
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  ComputedRef,
} from "vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import NewClientModal from "@/components/modals/forms/NewClientModal.vue";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { Client } from "@/core/types/ClientTypes";

export default defineComponent({
  name: "clients",
  components: {
    NewClientModal,
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);

    const clients = computed(() => {
      return store.getters.getClientList;
    });
    const searchClients = ref("");

    store
      .dispatch(Actions.GET_CLIENTS)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving clients.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onMounted(() => {
      setCurrentPageTitle("Clients");
      setCurrentPageBreadcrumbs("Client List", ["Admin"]);
    });

    const filterClients: ComputedRef<Array<Client>> = computed(() => {
      return clients.value.filter((client) => {
        return (
          client.name
            .toLowerCase()
            .indexOf(searchClients.value.toLowerCase()) != -1
        );
      });
    });

    watch(clients, () => {
      isDataReady.value = true;
    });

    const deleteClient = (id) => {
      Swal.fire({
        title:
          "Are you sure you want to archive this client and deactivated all users?",
        showCancelButton: true,
        confirmButtonText: "Yes, archive client.",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_CLIENT, id)
            .then(() => {
              Swal.fire(
                "Success. The client has been archived and the users have been deactivated !",
                "",
                "success"
              );
              for (let i = 0; i < clients.value.length; i++) {
                if (clients.value[i].id === id) {
                  clients.value.splice(i, 1);
                }
              }
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem deleting the client.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    return {
      currentUser,
      isDataReady,
      displayModal,
      clients,
      searchClients,
      filterClients,
      deleteClient,
    };
  },
});
